import React, { FC, useEffect, useState } from 'react'
// Components & Helpers
import LoadingSpinner from 'components/LoadingSpinner'
import { AuthContext, initialAuthContextValue } from './AuthContext'
import { APIHelper } from 'helpers/APIHelper'
// Types
import { ReportConfig } from 'types/report'
import { Props } from './AuthContext.d'

export const AuthProvider: FC<Props> = (props) => {
    const apiHelper = APIHelper.getInstance()
    const [reportConfig, setReportConfig] = useState<ReportConfig>(initialAuthContextValue)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        requestToken()
    }, [])

    useEffect(() => {
        if (reportConfig.token) {
            const intervalId = setInterval(() => checkTokenAndUpdate(), 30000)

            return () => {
                clearInterval(intervalId)
            }
        }
    }, [reportConfig.token])

    const requestToken = () => {
        const headers = apiHelper.getHeaders()
        apiHelper
            .get('/report', headers)
            .then((response) => {
                const report: ReportConfig = response.data
                setReportConfig({
                    token: report.token,
                    embedURL: report.embedURL,
                    tabs: report.tabs,
                    expiration: new Date(report.expiration).valueOf(),
                    sessionToken: report.sessionToken,
                })
            })
            .catch((err) => {
                setReportConfig((prevConfig) => ({ ...prevConfig, error: err?.response?.data?.error }))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const checkTokenAndUpdate = () => {
        const currentTime = Date.now().valueOf()
        if (reportConfig.expiration < currentTime) {
            console.log('PBI: Requesting a new token')
            requestRefreshToken()
        }
    }

    const requestRefreshToken = () => {
        apiHelper
            .get(`/token?session_token=${reportConfig.sessionToken}`)
            .then((response) => {
                setReportConfig({
                    ...reportConfig,
                    expiration: new Date(response.data.expiration).valueOf(),
                    token: response.data.token,
                })
            })
            .catch((err) => {
                setReportConfig((prevConfig) => ({ ...prevConfig, error: err?.response?.data?.error }))
            })
    }

    return (
        <AuthContext.Provider value={reportConfig}>
            {loading ? <LoadingSpinner /> : props.children}
        </AuthContext.Provider>
    )
}
