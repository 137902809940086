import React, { FC, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Box from '@mui/material/Box'
import ExportPDF from 'components/ExportPDF'
// Types
import { UITab } from 'types/ui'
// Styles
import * as Styled from './NavigationBar.styled'

interface Props {
    tabs: UITab[]
    onChangeTab: (val: UITab) => void
}

const NavigationTabs: FC<Props> = ({ tabs, onChangeTab }) => {
    const [currentTab, setCurrentTab] = useState(tabs[0].id)

    const handleChangeTab = (event: React.SyntheticEvent, val: string) => {
        const selectedTab = tabs.find((tab: UITab) => tab.id === val)
        if (!selectedTab) {
            return
        }
        setCurrentTab(val)
        onChangeTab(selectedTab)
    }

    return (
        <Styled.NavigationHeader>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentTab} onChange={handleChangeTab} aria-label="powerBI-tabs">
                    {tabs.map((tab: UITab) => (
                        <Styled.Tab key={tab.id} label={tab.label} value={tab.id} />
                    ))}
                <ExportPDF />
                </Tabs>
            </Box>
        </Styled.NavigationHeader>
    )
}

export default NavigationTabs
