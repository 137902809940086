import React, { useContext, useEffect, useState } from 'react'
import { Button, LinearProgress, Popover } from '@mui/material'
import { useSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
// Contexts
import { AuthContext } from 'context/AuthContext'
import { ReportContext } from 'context/ReportContext'
// Helpers
import { APIHelper } from 'helpers/APIHelper'
import { initialExportStatus, slicersQueryParams } from './ExportPDF.helpers'
// Types
import { ExportSlicers, ExportStatus, ExportStatusError } from 'types/pdfExport'
// Styles
import * as Styled from './ExportPDF.styled'

export default function ExportPDF() {
    let intervalId: NodeJS.Timer
    const apiHelper = APIHelper.getInstance()
    const { sessionToken } = useContext(AuthContext)
    const { slicers } = useContext(ReportContext)
    const { enqueueSnackbar } = useSnackbar()

    const [popoverOpened, setPopoverOpened] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    const [exportId, setExportId] = useState<string | null>(null)
    const [inProgress, setInProgress] = useState(false)
    const [exportStatus, setExportStatus] = useState<ExportStatus>(initialExportStatus)

    /**
     * Request an update from BE every 5 seconds to get the latest export state.
     */
    useEffect(() => {
        if (exportId) {
            intervalId = setInterval(() => updateExportStatus(), 5000)
            return () => {
                clearInterval(intervalId)
            }
        }
    }, [exportId])

    /**
     * In case there is an ongoing PDF export, display the popover only.
     * In case there is no ongoing PDF export, display the popover, request the PDF export,
     * and update `exportId` state which will trigger the interval update (in `useEffect`).
     */
    const onExportBtnClicked = async (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        setPopoverOpened(true)
        if (!inProgress) {
            setInProgress(true)
            setExportStatus(initialExportStatus)
            try {
                const slicersQuery = getSlicersQuery()
                const res = await apiHelper.get(`/request_export?session_token=${sessionToken}&${slicersQuery}`)
                setExportId(res.data?.exportID)
            } catch (err) {
                enqueueSnackbar((err as ExportStatusError)?.message, { variant: 'error' })
                setInProgress(false)
                setAnchorEl(null)
                setPopoverOpened(false)
                console.log('PBI: Error exporting PDF', err)
            }
        }
    }

    const getSlicersQuery = () => {
        const queryParams = new URLSearchParams()
        Object.keys(slicers).forEach((slicerName) => {
            const filterKey = slicersQueryParams[slicerName as keyof ExportSlicers]
            const filterValue = slicers[slicerName as keyof ExportSlicers]
            if (filterKey && filterValue) {
                queryParams.append(filterKey, filterValue)
            }
        })
        return queryParams.toString()
    }

    const updateExportStatus = async () => {
        try {
            const res = await apiHelper.get(`/poll_export_status?session_token=${sessionToken}&export_id=${exportId}`)
            const exportStatus: ExportStatus = res.data
            setExportStatus(exportStatus)
            if (exportStatus.completed) {
                onExportCompleted()
            }
        } catch (err) {
            enqueueSnackbar((err as ExportStatusError)?.message, { variant: 'error' })
            console.log('PBI: Error getting status of PDF export', err)
        }
    }

    const onExportCompleted = async () => {
        clearInterval(intervalId)
        enqueueSnackbar('PDF exported successfully!', { variant: 'success' })
        setInProgress(false)
        setPopoverOpened(true)
        downloadPDF()
        console.log('PBI: PDF exported and downloaded')
    }

    const downloadPDF = () => {
        const downloadLink = document.createElement('a')
        downloadLink.href = `${apiHelper.getAPIUrl()}/download_export?session_token=${sessionToken}&export_id=${exportId}`
        downloadLink.target = '_blank'
        downloadLink.click()
    }

    const onClosePopover = () => {
        setPopoverOpened(false)
    }

    return (
        <>
            <Styled.ExportButton variant="outlined" onClick={onExportBtnClicked}>
                Export PDF
                {inProgress && <Styled.LoadingIcon size={15} />}
            </Styled.ExportButton>
            <Popover
                open={popoverOpened}
                anchorEl={anchorEl}
                onClose={onClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <Styled.PopoverContent>
                    <Styled.PopoverHeader>
                        <header>Report is being generated. This could take a few minutes.</header>
                        <Styled.IconButton onClick={onClosePopover}>
                            <CloseIcon />
                        </Styled.IconButton>
                    </Styled.PopoverHeader>
                    <Styled.PercentParagraph>{exportStatus.percent}% Completed.</Styled.PercentParagraph>
                    <LinearProgress variant="determinate" value={exportStatus.percent} />
                    {exportStatus.completed ? (
                        <Styled.DownloadMessage>
                            Your report has been generated.
                            <br />
                            If the download doesn&apos;t start automatically, please click here to
                            <Button
                                href={`${apiHelper.getAPIUrl()}/download_export?session_token=${sessionToken}&export_id=${exportId}`}
                            >
                                Download
                            </Button>
                        </Styled.DownloadMessage>
                    ) : (
                        <Styled.DisclaimerMessage>
                            Please note that the PDF is a simplified version of the main Reports. Supported filters
                            include
                            <br />
                            only Time period and Sub-Groups. There will be limitation on the data displayed for large
                            tables
                            <br />
                            & charts. Selecting filtering criteria beyond the scope of those limitations may produce
                            <br />
                            unexpected visual results.
                        </Styled.DisclaimerMessage>
                    )}
                </Styled.PopoverContent>
            </Popover>
        </>
    )
}
