import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from 'context/AuthContext'
import AppRoutes from './router/AppRoutes'
import * as Styled from './styled'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <BrowserRouter>
        <Styled.NotificationProvider>
            <AuthProvider>
                <AppRoutes />
            </AuthProvider>
        </Styled.NotificationProvider>
    </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
