import { IReportEmbedConfiguration, models } from 'powerbi-client'
import { ReportConfigTabs } from 'types/report'
import { UITab } from 'types/ui'

export const getInitialReportConfig = (embedURL: string, token: string): IReportEmbedConfiguration => {
    return {
        type: 'report',
        embedUrl: embedURL,
        tokenType: models.TokenType.Embed,
        accessToken: token,
        pageName: undefined,
        settings: {
            layoutType: models.LayoutType.Custom,
            customLayout: {
                displayOption: models.DisplayOption.FitToWidth,
            },
            bars: {
                actionBar: {
                    visible: true,
                },
            },
            navContentPaneEnabled: false,
            filterPaneEnabled: false,
            panes: {
                bookmarks: {
                    visible: false,
                },
                fields: {
                    expanded: true,
                },
                filters: {
                    expanded: false,
                    visible: false,
                },
                pageNavigation: {
                    visible: false,
                },
                selection: {
                    visible: true,
                },
                syncSlicers: {
                    visible: true,
                },
                visualizations: {
                    expanded: true,
                },
            },
        },
    }
}

export const normalizeUITabs = (tabsResponse: ReportConfigTabs): UITab[] => {
    return Object.keys(tabsResponse)
        .sort()
        .map((tabIndex: string) => {
            const tab = tabsResponse[tabIndex]
            return {
                label: tab.name.charAt(0).toUpperCase() + tab.name.slice(1),
                id: tab.pageName,
                filters: tab.filters,
                skipTimeFilter: tab.skipTimeFilter
            }
        })
}
