import React from 'react'
import { ReportConfig } from 'types/report'

export const initialAuthContextValue = {
    token: '',
    embedURL: '',
    tabs: {},
    expiration: 0,
    sessionToken: '',
}

export const AuthContext = React.createContext<ReportConfig>(initialAuthContextValue)
